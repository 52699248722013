/*
 * File: Checkbox.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on January 24, 2022 at 9:59 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import * as S from './Checkbox.styles';


/**
 * Component
 */

export const Checkbox = forwardRef(({
	containerClassName, value, onChange, ...rest
}, ref) => (
	<S.Wrapper className={containerClassName}>
		<S.CheckboxWrapper>
			<S.CheckboxFlex>
				<S.Checkbox ref={ref} checked={value} onClick={onChange} onChange={() => {}} {...rest} />
				<FontAwesomeIcon className="checkmark" icon={['fa', 'check']} />
			</S.CheckboxFlex>
		</S.CheckboxWrapper>
	</S.Wrapper>
));


/**
 * Configuration
 */

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
	value: PropTypes.bool,
	onChange: PropTypes.func,
	containerClassName: PropTypes.string
};
Checkbox.defaultProps = {
	value: false,
	onChange: null,
	containerClassName: null
};
