/*
 * File: global.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createGlobalStyle } from 'styled-components';

// Constants
import { mobileBreakpoint } from './constants';


/**
 * Styles
 */

export const GlobalStyles = createGlobalStyle`

	/**
	 * Element Styles
	 */

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	html,
	body,
	#root {
		min-height: 100%;
		height: 100%;
		background-color: ${({ theme }) => theme.primaryBackground};

		& > div:first-child {
			height: 100%;
			width: 100%;
		}
	}
	body, button, input, textarea {
		font-family: 'Open Sans', sans-serif;
		color: ${({ theme }) => theme.primaryText};
	}
	h1, h2, h3, h4, h5 {
		font-family: 'Open Sans', sans-serif;
	}

	/*
	 * Animation Styles
	 */

	.animate {
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
	}
	.preload * {
		transition: none !important;
		-webkit-transition: none !important;
		-moz-transition: none !important;
		-ms-transition: none !important;
		-o-transition: none !important;
	}
	.shimmer {
		animation : shimmer 2s infinite;
   		background: linear-gradient(to right, ${({ theme }) => theme.placeholderPrimary} 4%, ${({ theme }) => theme.placeholderHighlight} 25%, ${({ theme }) => theme.placeholderPrimary} 36%);
  		background-size: 1000px 100%;
	}
	.shimmer-element {
		border-radius: 12px;
		color: transparent !important;
	}
	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}

	/**
	 * Element Styles
	 */

	svg.heart * {
		color: ${({ theme }) => theme.brandGreen};
	}

	/**
	 * Placeholder Styles
	 */

	input::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}
	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}
	textarea::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}
	textarea:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	textarea::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	textarea:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}

	/**
	 * Breakpoints
	 */

	.isMobile {
		display: none !important;

		@media only screen and (max-width: ${mobileBreakpoint}em) {
			display: inherit !important;
		}
	}
	.isNotMobile {
		display: inherit !important;

		@media only screen and (max-width: ${mobileBreakpoint}em) {
			display: none !important;
		}
	}
`;
