/*
 * File: Button.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 11:24 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Constants
 */

const SIZES = {
	small: css`
		padding: 0.3125rem 0.625rem;
	`,
	medium: css`
		padding: 0.5625rem 1.5625rem;
	`,
	large: css`
		padding: 0.8625rem 1.8625rem;
	`,
};

const VARIATIONS = {
	default: {
		solid: css`
			background-color: ${({ theme }) => theme.brandGreen};
			border-color: ${({ theme }) => theme.brandGreen};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.brandGreen};
			color: ${({ theme }) => theme.brandGreen};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.brandGreen};
		`,
	},
	light: {
		solid: css`
			background-color: ${({ theme }) => theme.lightButton};
			border-color: ${({ theme }) => theme.lightButton};
			color: ${({ theme }) => theme.brandGreen};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.brandGreen};
			color: ${({ theme }) => theme.brandGreen};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.brandGreen};
		`,
	},
	white: {
		solid: css`
			background-color: ${ColorValues.white};
			border-color: ${ColorValues.white};
			color: ${ColorValues.dark};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${ColorValues.white};
			color: ${ColorValues.white};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${ColorValues.white};
		`,
	},
	dark: {
		solid: css`
			background-color: ${({ theme }) => theme.darkButton};
			border-color: ${({ theme }) => theme.darkButton};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.darkButton};
			color: ${({ theme }) => theme.darkButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.darkButton};
		`,
	},
	secondary: {
		solid: css`
			background-color: ${({ theme }) => theme.secondaryButton};
			border-color: ${({ theme }) => theme.secondaryButton};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.secondaryButton};
			color: ${({ theme }) => theme.secondaryButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.secondaryButton};
		`,
	},
	warning: {
		solid: css`
			background-color: ${({ theme }) => theme.statusDangerBase};
			border-color: ${({ theme }) => theme.statusDangerBase};
			color: ${({ theme }) => theme.primaryBackground};
		`,
		outline: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.statusDangerBase};
			color: ${({ theme }) => theme.statusDangerBase};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.statusDangerBase};
		`,
	},
};


/**
 * Styles
 */

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border-radius: 0px;
	letter-spacing: 0.04rem;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	font-weight: 700;
	font-size: 0.9rem;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;

	* {
		letter-spacing: 0.04rem;
	}

	& > * + * {
		margin-left: 0.625rem;
	}
	.noMargin {
		margin-left: 0px;
	}

	${({ size, variant, variation }) => css`
		${SIZES[size]}
		${VARIATIONS[variation][variant]}
	`}

	&:hover {
		opacity: 0.8;
	}

	&:disabled {
		opacity: 0.8;
		cursor: default;
	}
`;
