/*
 * File: index.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:35 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import componentReducer from './slices/component/component.slice';
import localeReducer from './slices/locale/locale.slice';
import consentReducer from './slices/consent/consent.slice';
import uiReducer from './slices/ui/ui.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		component: componentReducer,
		locale: localeReducer,
		consent: consentReducer,
		ui: uiReducer
	},
});
