/*
 * File: state.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 26, 2022 at 10:21 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Helpers
 */

export const filterData = (path, props) => {

	// If data is undefined, return
	if (!props) return props;

	// Create data object from props
	return {
		...props.global ? props.global : undefined,
		...props[path] ? props[path] : undefined,
	};
};


/**
 * Utilities
 */

export const getDataStateForPage = (componentState, setComponentData, callback, dispatch, func, funcParams = {}) => {

	// Ensure app component state is final
	if (componentState.status === 'final') {

		// Attempt to find current component state for path
		const currentState = componentState.states.slice().reverse().find((state) => !state.isUsed && state.path === window.location.pathname);

		// Get current timestamp
		const timestamp = new Date();

		// Check if current state exists and state is less than 10 minutes old
		if (currentState && (timestamp.getTime() - currentState.timestamp) < 10 * 60 * 1000) {

			// Check if current state is error
			if (currentState.data.isError !== true) {
				setComponentData(currentState.data);
				if (dispatch && func) {
					dispatch(func(funcParams));
				} else if (func) {
					func(funcParams);
				}
				callback({ requiresLoad: false, data: currentState.data });
			} else {
				callback(currentState.data || { requiresLoad: false });
			}
		} else if (callback) {
			callback({ requiresLoad: true });
		}
	}
};

export const getDataStateForComponent = (componentState, setComponentData, component, callback, dispatch, func, funcParams = {}) => {

	// Ensure app component state is final
	if (componentState.status === 'final') {

		// Attempt to find current component state for path
		const currentState = componentState.states[component];

		// Get current timestamp
		const timestamp = new Date();

		// Check if current state exists and state is less than 10 minutes old
		if (currentState && (timestamp.getTime() - currentState.timestamp) < 10 * 60 * 1000) {

			// Check if current state is error
			if (currentState.data.isError !== true) {
				setComponentData(currentState.data);
				if (dispatch && func) {
					dispatch(func(funcParams));
				} else if (func) {
					func(funcParams);
				}
				callback({ requiresLoad: false });
			} else {
				callback(currentState.data || { requiresLoad: false });
			}
		} else if (callback) {
			callback({ requiresLoad: true });
		}
	}
};

export const getDataForSection = (section, serverData, fallback) => {
	if (section.data != null) {
		return section.data;
	}
	if (serverData != null && serverData[section.dataKey] != null) {
		return serverData[section.dataKey];
	}
	return fallback;
};
