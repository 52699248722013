/*
 * File: AppNavigation.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 12:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	createContext
} from 'react';
import PropTypes from 'prop-types';

// Components
import { Topbar } from './Topbar';
import { CopyrightFooter } from '../CopyrightFooter';

// Styles
import * as S from './AppNavigation.styles';


/**
 * Context
 */

export const AppNavigationContext = createContext();


/**
 * Component
 */

export const AppNavigation = ({
	children, isLarge
}) => (
	<S.Navigation>

		{/* Top Bar */}
		<Topbar
			isLarge={isLarge}
		/>

		{/* Content */}
		<S.Wrapper>
			<S.Content id="scroller">

				{/* Content */}
				<S.ContentPadding left right dynamic isLarge={isLarge}>
					{children}
				</S.ContentPadding>

				{/* Footer */}
				<CopyrightFooter isLarge={isLarge} />
			</S.Content>
		</S.Wrapper>
	</S.Navigation>
);


/**
 * Configuration
 */

AppNavigation.displayName = 'AppNavigation';
AppNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	isLarge: PropTypes.bool,
};
AppNavigation.defaultProps = {
	children: null,
	isLarge: false,
};
