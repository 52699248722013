/*
 * File: index.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:03 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

// Polyfill IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Modules
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { useSSR } from 'react-i18next';

// Styles
import { config } from '@fortawesome/fontawesome-svg-core';
import { loadIcons } from '../styles/fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

// Utilities
import { buildInstance, buildOptions } from '../../utilities/i18n';

// Stores
import store from '../store';

// App
import App from './App';

// Components
import { Spinner } from '../components';

// Constants
import { DEFAULT_LOCALE } from '../../Constants';

// Configuration
config.autoAddCss = false;


/**
 * Constants
 */

const isDevelopment = process.env.ENV === 'development';
const isHot = import.meta.webpackHot != null;


/**
 * Accept Hot Reload
 */

if (isHot && isDevelopment) {
	import.meta.webpackHot.accept();
}


/**
 * Load Icons
 */

loadIcons();


/**
 * Initialize i18n
 */

buildInstance({
	...buildOptions(false, isHot),
	loadPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.json`,
	addPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.missing.json`,
	backend: {
		loadPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.json`,
		addPath: `${process.env.APP_URL}/locales/{{lng}}/{{ns}}.missing.json`
	}
}, false);


/**
 * Render App
 */

// Determine render function
const isMarkupPresent = document.getElementById('root').hasChildNodes();
const renderFunction = isMarkupPresent ? ReactDOM.hydrate : ReactDOM.render;

// Create inner application component
const BaseApp = () => (
	<HelmetProvider>
		<BrowserRouter>
			<App isHot={isHot} />
		</BrowserRouter>
		<Toaster />
	</HelmetProvider>
);

// Create application component
const ParentApp = () => {

	// Get data if SSR mode
	if (!isHot) {

		// Get component state on client
		let state = { locale: { initialI18nStore: {}, initialLanguage: DEFAULT_LOCALE } };
		try { state = JSON.parse(decodeURIComponent(window.document.getElementById('data-state').innerHTML)); } catch (e) { }

		// Use SSR from hook
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSSR(state.locale.initialI18nStore, state.locale.initialLanguage);
	}

	// Render components
	return (
		<React.StrictMode>
			<Provider store={store}>
				{isHot ? (
					<Suspense fallback={<Spinner />}>
						<BaseApp />
					</Suspense>
				) : (
					<BaseApp />
				)}
			</Provider>
		</React.StrictMode>
	);
};

// Prepare content
renderFunction(
	<ParentApp />,
	document.getElementById('root')
);
