/*
 * File: Emoji.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 2:01 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Emoji = styled.span`
	font-weight: 400;
	font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
	font-size: ${({ size }) => size}rem !important;
`;
