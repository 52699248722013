/*
 * File: IconButton.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import * as S from './IconButton.styles';


/**
 * Component
 */

export const IconButton = forwardRef(({
	size, icon, type, className, ...rest
}, ref) => (
	<S.Button ref={ref} size={size} type={type} className={className ? `${className} animate` : 'animate'} {...rest}>
		<FontAwesomeIcon icon={icon} />
	</S.Button>
));


/**
 * Configuration
 */

IconButton.displayName = 'IconButton';
IconButton.propTypes = {
	size: PropTypes.number,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
	className: PropTypes.string,
	type: PropTypes.string
};
IconButton.defaultProps = {
	size: 1.75,
	className: null,
	type: 'button'
};
