/*
 * File: Button.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Button.styles';


/**
 * Component
 */

export const Button = forwardRef(({
	children, size, variant, variation, type, className, ...rest
}, ref) => (
	<S.Button ref={ref} size={size} variant={variant} variation={variation} type={type} className={className ? `${className} animate` : 'animate'} {...rest}>
		{children}
	</S.Button>
));


/**
 * Configuration
 */

Button.displayName = 'Button';
Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['large', 'medium', 'small']),
	variant: PropTypes.oneOf(['solid', 'outline', 'text']),
	variation: PropTypes.oneOf(['default', 'light', 'white', 'dark', 'secondary', 'warning']),
	className: PropTypes.string,
	type: PropTypes.string
};
Button.defaultProps = {
	children: null,
	size: 'medium',
	variant: 'solid',
	variation: 'default',
	className: null,
	type: 'button'
};
