/*
 * File: NavigationDropdown.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:54 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 10:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { LocaleLink } from '../LocaleLink';

// Styles
import { ColorValues } from '../../styles/colors';

// Constants
import { mobileBreakpoint } from '../../styles/constants';
import { UI_MODE_OPTIONS } from '../../../Constants';


/**
 * Styles
 */

export const NavigationDropdown = styled.div`
	position: absolute;
	height: auto !important;
	z-index: 99;
	background-color: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.primaryBackground : theme.secondaryBackground)} !important;
	border-radius: 8px;
	box-shadow: ${ColorValues.elementShadow};
	right: ${({ adjustOnMobile }) => (adjustOnMobile ? '-65px' : '-13px')};
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	pointer-events: none;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;
		pointer-events: all;
	}

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		right: -13px;
	}
`;

export const DropdownArrow = styled.svg`
	right: ${({ adjustOnMobile }) => (adjustOnMobile ? '67px' : '15px')};
	display: block;
	width: 24px;
	height: 8px !important;
	position: absolute;
	top: -8px;
	fill: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.primaryBackground : theme.secondaryBackground)} !important;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		right: 15px;
	}
`;

export const NavigationContainer = styled.ul`
	line-height: 22px;
	padding: 8px 0px;
	margin: 0px;
	min-width: 225px;
	list-style: none;
	height: auto !important;
	color: ${({ theme }) => theme.primaryText};
`;

export const NavigationItem = styled.li`
	padding: 10px 16px;
	height: auto !important;
	font-size: 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	position: relative;

	&:hover {
		background-color: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.secondaryBackground : ColorValues.neutralHighlightDarker)};
	}
	span {
		flex-grow: 1;
	}
`;

export const NavigationIcon = styled(FontAwesomeIcon)`
	font-size: 1.12rem !important;
	width: 25px !important;
	display: flex !important;
	align-items: center;
	position: relative;
	margin-left: ${({ offset }) => `${offset}px`};
	margin-top: -1px;
`;

export const IconContainer = styled.div`
	width: 25px;
	margin-right: 8px;
	position: relative;

	svg {
		font-size: 1.2rem;
	}
`;

export const Badge = styled.div`
	width: 9px;
	height: 9px !important;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border-radius: 50%;
`;

export const Link = styled(LocaleLink)`
	text-decoration: none;
	color: ${({ theme }) => theme.primaryText};
	display: flex;
	align-items: center;
	width: 100%;
`;

export const Footer = styled.div`
	border-top: 1px solid ${({ theme }) => theme.layoutBorder};
	margin-top: 10px;
	padding-top: 10px;
`;
