/*
 * File: Checkbox.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:58 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: inline-flex;
	flex-shrink: 0;
	cursor: pointer;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	display: block;
	appearance: none;
	cursor: pointer;
	height: 27px;
	width: 27px;
	border-radius: 0px;
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.layoutBorder};

	&:checked {
		border-color: ${({ theme }) => theme.brandGreen};
		background-color: ${({ theme }) => theme.brandGreen};
	}
`;

export const CheckboxWrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: flex-start;
	cursor: pointer;

	.checkmark {
		position: absolute;
		width: 1rem;
		height: 1rem;
		color: white;
		pointer-events: none !important;
		opacity: 0;
		cursor: pointer;
	}

	${Checkbox}:checked + .checkmark {
		opacity: 1;
	}
`;

export const CheckboxFlex = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
`;
