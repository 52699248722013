/*
 * File: Error.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import { Meta, AppNavigation, ErrorComponent } from '../../../components';

// Styles
import * as S from './Error.styles';


/**
 * Component
 */

const Error = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>
					<ErrorComponent />
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Error.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Error.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Error;
