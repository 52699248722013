/*
 * File: toaster.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:40 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import toast from 'react-hot-toast';

// Styles
import { DarkTheme, LightTheme } from '../styles/colors';

// Constants
import { UI_MODE_OPTIONS } from '../../Constants';


/**
 * Utilities
 */

const toastMessage = (uiMode, message, options) => {

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Make toast
	toast(message, {
		icon: '👋',
		style: {
			background: currentTheme.primaryBackground,
			color: currentTheme.primaryText,
		},
		...options
	});
};

const toastSuccess = (uiMode, message, options) => {

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Make toast
	toast.success(message, {
		style: {
			background: currentTheme.primaryBackground,
			color: currentTheme.primaryText,
		},
		...options
	});
};

const toastError = (uiMode, message, options) => {

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Make toast
	toast.error(message, {
		style: {
			background: currentTheme.primaryBackground,
			color: currentTheme.primaryText,
		},
		...options
	});
};


/**
 * Exports
 */

export {
	toastMessage,
	toastSuccess,
	toastError
};
