/*
 * File: Topbar.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 11:28 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Padding } from '../../Padding';
import { LocaleLink } from '../../LocaleLink';

// Styles
import * as S from './Topbar.styles';


/**
 * Component
 */

export const Topbar = ({
	isLarge
}) => {

	// Create state handlers
	const [showNavigationDropdown, setShowNavigationDropdown] = useState(false);

	// Show navigation dropdown
	const toggleNavigation = () => {
		setShowNavigationDropdown(!showNavigationDropdown);
	};

	// Render component
	return (
		<S.Topbar>
			<Padding left right isLarge={isLarge}>
				<S.TopbarWrapper>
					<S.NavigationToggle icon={['fa', 'bars']} size={1.25} onClick={() => { toggleNavigation(); }} />
				</S.TopbarWrapper>
				<S.TopbarWrapper fullWidth>
					<LocaleLink to="/">
						<S.LogoImage alt="toxic ui logo" title="toxic ui logo" src={`${process.env.CDN_URL}/public/assets/toxic-ui-logo.png`} />
					</LocaleLink>
				</S.TopbarWrapper>
				<S.TopbarWrapper />
				<S.NavigationDropdown
					className={showNavigationDropdown ? 'animate auth show' : 'auth animate'}
					items={[
						{
							icon: ['fas', 'question-circle'],
							title: 'Home',
							link: '/'
						},
						{
							icon: ['fas', 'home-alt'],
							title: 'About',
							link: '/about',
						}
					]}
				/>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	isLarge: PropTypes.bool,
};
Topbar.defaultProps = {
	isLarge: false,
};
