/*
 * File: TextArea.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on April 15, 2022 at 1:22 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './TextArea.styles';


/**
 * Component
 */

export const TextArea = forwardRef(({
	id, label, error, type, size, containerClassName, ...rest
}, ref) => (
	<S.Wrapper error={error} className={containerClassName}>
		{label && (
			<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate">
				{label}
			</Typography>
		)}
		<S.TextArea ref={ref} id={id} error={error} {...rest} type={type} size={size} className={rest.className ? `${rest.className} animate` : 'animate'} />
		{error && error.message && (
			<S.FloatingWrapper title={error.message} className="animate">
				<Typography tag="p" variation="3" className="animate">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
));


/**
 * Configuration
 */

TextArea.displayName = 'TextArea';
TextArea.propTypes = {
	type: PropTypes.oneOf(['text', 'password', 'email', 'search']),
	size: PropTypes.oneOf(['fit', 'small', 'medium']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	error: PropTypes.shape(),
};
TextArea.defaultProps = {
	type: 'text',
	size: 'medium',
	placeholder: '',
	label: '',
	containerClassName: null,
	error: null,
	id: null
};
