/*
 * File: ErrorComponent.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:17 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 11:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Meta } from '../Meta';
import { Typography } from '../Typography';
import { Button } from '../Button';

// Styles
import * as S from './ErrorComponent.styles';


/**
 * Component
 */

export const ErrorComponent = ({
	locale, title, description, message, buttons
}) => (
	<>
		{/* Meta */}
		<Meta
			meta={{
				title: 'oh no',
				description: 'we can\'t find the page you\'re looking for',
				bodyClasses: 'hide-captcha',
				showSocial: false,
			}}
			locale={locale}
		/>

		{/* Component Content */}
		<S.Wrapper>
			<S.ContentContainer>
				<S.MessageContainer>
					<Typography tag="h1" weight="semibold">
						{title || 'oh no'}
					</Typography>
					<S.DescriptionContainer>
						<Typography tag="h4" weight="light">
							{description || 'we can\'t find the page you\'re looking for'}
						</Typography>
					</S.DescriptionContainer>
					{message !== '' && (
						<Typography tag="h4" weight="semibold">
							{message || 'find more toxicity'}
						</Typography>
					)}
				</S.MessageContainer>
				<S.ActionContainer fullWidth={!buttons}>
					{
						buttons ? buttons.map((button) => (
							<S.ButtonLink to={button.link} key={button.text}>
								<Button size={button.size} variant={button.variant || 'solid'}>{button.text}</Button>
							</S.ButtonLink>
						)) : (
							<S.ButtonLink to="/">
								<Button size="large">home</Button>
							</S.ButtonLink>
						)
					}
				</S.ActionContainer>
			</S.ContentContainer>
		</S.Wrapper>
	</>
);


/**
 * Configuration
 */

ErrorComponent.displayName = 'ErrorComponent';
ErrorComponent.propTypes = {
	locale: PropTypes.shape(),
	title: PropTypes.string,
	description: PropTypes.node,
	message: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.shape())
};
ErrorComponent.defaultProps = {
	locale: {},
	title: null,
	description: null,
	message: null,
	buttons: null
};
