/*
 * File: CookieBar.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:58 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Constants
import { smallBreakpoint, mediumBreakpoint } from '../../styles/constants';

// Colors
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const CookieBar = styled.div`
	height: auto;
	background-color: ${({ theme }) => theme.primaryBackground};
	position: fixed;
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: 998;
	padding-top: 20px;
	padding-bottom: 20px;
	box-shadow: ${ColorValues.cookieBarShadow};
	bottom: 0;
	transition: bottom 0.3s ease 0s;

	${(props) => !props.showBar && css`
		bottom: -${props.barHeight}px;
	`};

	.rightGutter {
		padding-right: 0px;
	}

	.small-screen-span {
		display: inline;
	}
	@media only screen and (max-width: ${smallBreakpoint}em) {
		.small-screen-span {
			display: none;
		}
	}
	@media only screen and (max-width: ${mediumBreakpoint}em) {
		&,
		& > div {
			display: block;
		}
		.button-container {
			margin: 20px 0px 0px 0px;
		}
	}
`;

export const TextContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1;

	p {
		text-align: left;
		font-size: 0.85rem;
		color: ${({ theme }) => theme.secondaryText};
	}

	p a,
	p a:link,
	p a:visited {
		text-decoration: underline;
		color: inherit;
		text-transform: inherit;
		font-weight: inherit;
		font-family: inherit;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 0px 0px 0px 30px;

	button {
		display: inline;
	}

	@media only screen and (max-width: ${mediumBreakpoint}em) {
		margin: 20px 0px 0px 0px;
	}
`;
