/*
 * File: ui.slice.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:03 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 9:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';

// Utilities
import { getCookie, setCookie } from '../../../utilities/cookies';

// Constants
import { UI_MODE_COOKIE, UI_MODE_OPTIONS, UI_MODE_COOKIE_EXPIRATION } from '../../../../Constants';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		mode: getCookie(UI_MODE_COOKIE) || UI_MODE_OPTIONS.LIGHT
	},
};


/**
 * Slices
 */

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		updateUI: (state, action) => {

			// Update cookie
			setCookie(UI_MODE_COOKIE, action.payload.mode, UI_MODE_COOKIE_EXPIRATION);

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearUI: (state) => {

			// Update cookie
			setCookie(UI_MODE_COOKIE, UI_MODE_OPTIONS.LIGHT, UI_MODE_COOKIE_EXPIRATION);

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateUI, clearUI } = uiSlice.actions;

export default uiSlice.reducer;
