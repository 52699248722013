/*
 * File: CopyrightFooter.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 11:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';
import { Padding } from '../Padding';
import { LocaleLink } from '../LocaleLink';

// Styles
import * as S from './CopyrightFooter.styles';


/**
 * Component
 */

export const CopyrightFooter = ({ isLarge }) => (
	<Padding left right isLarge={isLarge}>
		<S.CopyrightFooter>
			<LocaleLink to="/">
				<S.LogoMark alt="toxic ui logo" title="toxic ui logo" src={`${process.env.CDN_URL}/public/assets/toxic-ui-logo.png`} />
			</LocaleLink>
			<Typography tag="span">
				<LocaleLink to="https://www.lithiosapps.com">lithios</LocaleLink>
				{' '}
				wuz here
			</Typography>
		</S.CopyrightFooter>
	</Padding>
);


/**
 * Configuration
 */

CopyrightFooter.displayName = 'CopyrightFooter';
CopyrightFooter.propTypes = {
	isLarge: PropTypes.bool
};
CopyrightFooter.defaultProps = {
	isLarge: false
};
