/*
 * File: Spinner.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 6, 2022 at 12:13 AM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	width: 100%;
	height: 100%;
`;
