/*
 * File: colors.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Color Values
 */

export const ColorValues = {

	// Brand
	brandBlue: '#0973ba',
	brandGreen: '#259595',

	// Primary
	white: '#FFFFFF',
	dark: '#000000',

	// Neutral
	neutralButtonBase: '#747888',
	neutralButtonLighter: '#EEF0F6',
	neutralBackground: '#EFF1F3',
	neutralBackgroundDarker: '#39393B',
	neutralHighlight: '#E2E2E2',
	neutralHighlightDarker: '#505054',
	neutralForm: '#DBDFEC',
	neutralText: '#7F8496',
	neutralBorder: 'rgba(74,74,74,.05)',

	// Success
	statusSuccessBase: '#25953E',
	statusSuccessLighter: '#EAFFEE',

	// Warning
	statusWarningBase: '#E1D037',
	statusWarningLighter: '#FFFDE7',

	// Danger
	statusDangerBase: '#E13737',
	statusDangerLighter: '#FFEBEB',

	// Info
	statusInfoBase: '#1A4ED4',
	statusInfoLighter: '#E7EDFF',

	// Shadows
	navigationShadow: '0px 1px 1px rgb(0 0 0 / 15%)',
	elementShadow: '0px -3px 20px rgba(0, 0, 0, 0.06)',
	elementDarkShadow: '0px 4px 16px rgba(0 0 0 / 22%)',
};


/**
 * Themes
 */

export const DarkTheme = {

	// Brand
	brandBlue: ColorValues.brandBlue,
	brandGreen: ColorValues.brandGreen,

	// Success
	statusSuccessBase: ColorValues.statusSuccessBase,
	statusSuccessLighter: ColorValues.statusSuccessLighter,

	// Warning
	statusWarningBase: ColorValues.statusWarningBase,
	statusWarningLighter: ColorValues.statusWarningLighter,

	// Danger
	statusDangerBase: ColorValues.statusDangerBase,
	statusDangerLighter: ColorValues.statusDangerLighter,

	// Info
	statusInfoBase: ColorValues.statusInfoBase,
	statusInfoLighter: ColorValues.statusInfoLighter,

	// Background
	primaryBackground: ColorValues.dark,
	secondaryBackground: ColorValues.neutralBackgroundDarker,
	cardBackground: ColorValues.dark,

	// Text
	primaryText: ColorValues.white,
	secondaryText: ColorValues.neutralText,

	// Button
	primaryButton: ColorValues.brandGreen,
	secondaryButton: ColorValues.neutralButtonBase,
	darkButton: ColorValues.dark,
	lightButton: ColorValues.neutralBackgroundDarker,

	// Decoration
	primaryDecoration: ColorValues.white,
	secondaryDecoration: ColorValues.neutralBorder,
	layoutBorder: ColorValues.neutralText,
	navigationBorder: ColorValues.neutralText,
	inactiveElement: ColorValues.neutralText,
	placeholderPrimary: ColorValues.neutralBackgroundDarker,
	placeholderHighlight: ColorValues.neutralHighlightDarker,

	// Gradient
	primaryGradientStart: 'rgba(0,0,0,0)',
	primaryGradientEnd: 'rgba(0,0,0,1)',

	// Input
	inputBackground: ColorValues.dark,
	inputSecondayBackground: ColorValues.neutralBackground,
	inputBorder: ColorValues.neutralText,
	inputFocusBorder: ColorValues.neutralButtonBase,
	inputPlaceholder: ColorValues.neutralText,
};

export const LightTheme = {

	// Brand
	brandBlue: ColorValues.brandBlue,
	brandGreen: ColorValues.brandGreen,

	// Success
	statusSuccessBase: ColorValues.statusSuccessBase,
	statusSuccessLighter: ColorValues.statusSuccessLighter,

	// Warning
	statusWarningBase: ColorValues.statusWarningBase,
	statusWarningLighter: ColorValues.statusWarningLighter,

	// Danger
	statusDangerBase: ColorValues.statusDangerBase,
	statusDangerLighter: ColorValues.statusDangerLighter,

	// Info
	statusInfoBase: ColorValues.statusInfoBase,
	statusInfoLighter: ColorValues.statusInfoLighter,

	// Background
	primaryBackground: ColorValues.white,
	secondaryBackground: ColorValues.neutralBackground,
	cardBackground: ColorValues.white,

	// Text
	primaryText: ColorValues.dark,
	secondaryText: ColorValues.neutralText,

	// Button
	primaryButton: ColorValues.brandGreen,
	secondaryButton: ColorValues.neutralButtonBase,
	darkButton: ColorValues.dark,
	lightButton: ColorValues.brandGreen,

	// Decoration
	primaryDecoration: ColorValues.dark,
	secondaryDecoration: ColorValues.neutralBorder,
	layoutBorder: ColorValues.neutralForm,
	navigationBorder: 'transparent',
	inactiveElement: ColorValues.neutralText,
	placeholderPrimary: ColorValues.neutralBackground,
	placeholderHighlight: ColorValues.neutralHighlight,

	// Gradient
	primaryGradientStart: 'rgba(255,255,255,0)',
	primaryGradientEnd: 'rgba(255,255,255,1)',

	// Input
	inputBackground: ColorValues.white,
	inputSecondayBackground: ColorValues.neutralBackground,
	inputBorder: ColorValues.neutralForm,
	inputFocusBorder: ColorValues.neutralButtonBase,
	inputPlaceholder: ColorValues.neutralForm,
};
