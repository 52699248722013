/*
 * File: CopyrightFooter.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 11, 2022 at 10:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const CopyrightFooter = styled.div`
	position: relative;
	padding: 15px 0px 30px;
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.layoutBorder};

	* {
		text-align: left;
	}

	span {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.85rem;
		font-weight: 300;
	}
`;

export const LogoMark = styled.img`
	width: 34px;
	height: 34px;
	margin-right: 8px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;
