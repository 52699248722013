/*
 * File: fontawesome.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 11:27 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { library } from '@fortawesome/fontawesome-svg-core';

// Icons
import {
	faBars,
	faTimes,
	faHeart,
	faCheck,
	faHomeAlt,
	faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';


/**
 * Init
 */

export function loadIcons() {

	// Add icons to library
	library.add(
		faBars,
		faTimes,
		faHeart,
		faCheck,
		faHomeAlt,
		faQuestionCircle
	);
}
