/*
 * File: BirthdayChooser.styles.js
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on December 11, 2022 at 10:22 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 12, 2022 at 11:26 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	max-width: 400px;
	margin: 0px auto;

	@media only screen {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 140px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
		margin-top: 30px;
	}
`;

export const InputWrapper = styled.div`
	margin-top: 20px;
`;
