/*
 * File: BirthdayChooser.jsx
 * Project: toxic-ui-web
 *
 * Created by Brendan Michaelsen on December 11, 2022 at 10:21 PM
 * Copyright © 2022 Lithios, LLC. All rights reserved.
 *
 * Last Modified: December 14, 2022 at 12:32 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { toastError, toastSuccess } from '../../../../../utilities/toaster';
import { stringToDate } from '../../../../../../utilities/dateTime';

// Components
import {
	Typography, Button, Select, Spinner
} from '../../../../../components';

// Styles
import * as S from './BirthdayChooser.styles';
import { MONTH_NAMES } from '../../../../../../Constants';


/**
 * Helpers
 */

const generateDateOptions = () => {

	// Get date parameters
	const startDate = new Date();
	const endDate = new Date();
	startDate.setFullYear(endDate.getFullYear() - 100);

	// Get date options
	const arr = [];
	for (let dt = new Date(startDate.getTime()); dt.getTime() <= (new Date(endDate.getTime())).getTime(); dt.setDate(dt.getDate() + 1)) {
		const formattedDate = `${MONTH_NAMES[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`;
		arr.push({ value: formattedDate, label: formattedDate });
	}

	// Reverse array
	arr.reverse();

	// Return dates
	return [
		{ value: '', label: 'select a date' },
		...arr
	];
};


/**
 * Component
 */

export const BirthdayChooser = ({
	className
}) => {

	// Create state handlers
	const [componentStatus, setComponentStatus] = useState('idle');
	const [dateOptions, setDateOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [numberOfSelections, setNumberOfSelections] = useState(0);

	// Create references
	const numberOfSelectionsRef = useRef();

	// Update current references
	numberOfSelectionsRef.current = numberOfSelections;

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle component submission
	const handleSubmission = async () => {

		// Get parameters
		const { birthDate } = inputValues;

		// Validate parameters
		if (!birthDate || validator.isEmpty(birthDate, { ignore_whitespace: true })) {
			toastError(uiMode, 'pls follow instructions');
			return;
		}

		// Get date from string
		const dateObj = stringToDate(birthDate, 'MMMM D, YYYY');

		// Get year difference from current date
		const yearDiff = ((new Date()).getFullYear() - dateObj.getFullYear());

		// Get current number of submissions
		const selections = numberOfSelectionsRef.current;

		// Validate date
		if (selections < 2) {
			toastError(uiMode, 'yeah right, that\'s not your real birthday');
			return;
		}
		if (yearDiff <= 1) {
			toastError(uiMode, 'how did you even use this form, u were just born');
			return;
		}
		if (yearDiff <= 3) {
			toastError(uiMode, 'nice try, toddler');
			return;
		}
		if (yearDiff >= 50) {
			toastError(uiMode, 'okay boomer');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Show results
		if (birthDate === 'April 20, 1969') {
			toastSuccess(uiMode, 'lol nice');
		}

		// Set loading state
		setIsLoading(false);
	};

	// Handle on input change action
	const handleOnChange = (event) => {

		// Update number of submissions
		setNumberOfSelections(numberOfSelectionsRef.current + 1);

		// Update value
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Perform actions on component load
	useEffect(() => {

		// Set loading
		setComponentStatus('loading');

		// Set timeout
		setTimeout(() => {

			// Set date options
			const fullDateOptions = generateDateOptions();
			setDateOptions(fullDateOptions);

			// Update component status
			setComponentStatus('success');

		}, 1000);
	}, []);

	// Return component
	return (
		<S.Wrapper className={className}>
			{componentStatus === 'idle' || componentStatus === 'loading' ? <Spinner /> : (
				<>

					{/* Content */}
					<Typography tag="h2" weight="bold">Select your birthday to continue</Typography>

					{/* Selector */}
					<S.InputWrapper>
						<Select
							label="Your Birthday"
							name="birthDate"
							values={inputValues.birthDate ? [inputValues.birthDate] : []}
							options={dateOptions}
							onChange={handleOnChange}
							isPlain
						/>
					</S.InputWrapper>

					{/* Submission Button */}
					<Button disabled={isLoading} isLoading={isLoading} onClick={handleSubmission}>Continue</Button>
				</>
			)}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

BirthdayChooser.displayName = 'BirthdayChooser';
BirthdayChooser.propTypes = {
	className: PropTypes.string,
};
BirthdayChooser.defaultProps = {
	className: null,
};
